import moment from 'moment';
import { MagentoReviewsRepository } from '../interfaces/repositories/magentoReviewsRepository';
import { ReviewsService } from '../interfaces/services/reviewsService';
import { ProductReviews, ProductReviewsReview } from '../models/productReviews';
import { ImageService } from '../interfaces/services/imageService';
import { removeWhiteSpaces } from '@progymedia/sbi-web-drolet/src/utils';

type ReviewCache = {
    sku: string;
    productReviews: ProductReviews;
};

export class ReviewsServiceImpl implements ReviewsService {
    private magentoReviewsRepository: MagentoReviewsRepository;
    private imageService: ImageService;
    private reviewsCache: ReviewCache[];

    constructor(
        magentoReviewsRepository: MagentoReviewsRepository,
        imageService: ImageService
    ) {
        this.magentoReviewsRepository = magentoReviewsRepository;
        this.imageService = imageService;
        this.reviewsCache = [];
    }

    async getReviewsBySku(sku: string): Promise<ProductReviews | null> {
        const productReviews = this.reviewsCache.find((r) => r.sku == sku);

        if (productReviews) {
            return Promise.resolve(productReviews.productReviews);
        } else {
            return await this.magentoReviewsRepository
                .getReviewsBySku(sku)
                .then((productReviews: ProductReviews | null) => {
                    if (productReviews) {
                        this.reviewsCache.push({
                            sku: sku,
                            productReviews: productReviews,
                        });
                    }
                    return productReviews;
                })
                .catch((error: any) => {
                    throw error;
                });
        }
    }

    async getImageReviewUrls(sku: string): Promise<Record<string, string>> {
        const imageObjects: Record<string, string> = {};
        return await this.imageService
            .getReviewImageIdsBySku(sku)
            .then(async (ids: string[]) => {
                for await (const id of ids) {
                    const url =
                        await this.imageService.createObjectURLByImageId(id);
                    if (url) {
                        imageObjects[id] = url;
                    }
                }
                return imageObjects;
            })
            .catch((error: any) => {
                throw error;
            });
    }

    generateImageReviewId(review: ProductReviewsReview): string {
        const formatedDate = moment(review.created_at).format('YYYYMMDD');
        return removeWhiteSpaces(
            `${review.sku}-${review.nickname}-${formatedDate}`
        );
    }
}
