interface IMapObj {
    label: string;
    slug: string;
    name: string;
    category: string;
}
export function getCategoryMap(products: any, currentLocale: string) {
    const categoryMap: Map<string, IMapObj[]> = new Map();
    products.forEach((product: any) => {
        let re = /-/gi;
        const category = product.category;
        const categoryLocalized: string[] =
            product.category_localized[currentLocale].split('/');
        const Accessories = categoryLocalized[2].replace(re, ' ');
        let accessorySub: string;
        if (categoryLocalized[3] !== 'Allume-feux')
            accessorySub = categoryLocalized[3].replace(re, ' ');
        else accessorySub = categoryLocalized[3];

        const slug: string[] = product.slug[currentLocale].split('/');
        const slugValue = slug[1] + '/' + slug[2];
        const collection = categoryMap.get(Accessories);
        if (accessorySub?.trim() !== '') {
            if (!collection) {
                categoryMap.set(Accessories, [
                    {
                        label: accessorySub,
                        slug: slugValue,
                        name: slug[2],
                        category,
                    },
                ]);
            } else {
                if (!collection.find((e: any) => e.label === accessorySub)) {
                    collection.push({
                        label: accessorySub,
                        slug: slugValue,
                        name: slug[2],
                        category,
                    });
                }
            }
        }
    });
    categoryMap
        .values()
        .next()
        .value.sort((a: IMapObj, b: IMapObj) =>
            a?.label?.localeCompare(b?.label)
        );
    return categoryMap;
}
