import {
    GetUserProfileDocument,
    GetUserProfileQuery,
    GetUserProfileQueryVariables,
    useSubscribeNewsletterMutation,
} from '../graphql';

import react from 'react';

export interface ISubscribeNewsletterInput {
    email: string;
}

export const useSubscribeNewsletterAction = () => {
    const [subscribeNewsletterMutation, subscribeNewsletterResponse] =
        useSubscribeNewsletterMutation();

    const subscribeNewsletter = async (
        newsletterInput: ISubscribeNewsletterInput
    ) => {
        await subscribeNewsletterMutation({
            variables: newsletterInput,
            update: (cache) => {
                const userProfile = cache.readQuery<
                    GetUserProfileQuery,
                    GetUserProfileQueryVariables
                >({ query: GetUserProfileDocument });

                if (
                    userProfile?.customer &&
                    userProfile.customer.email === newsletterInput.email
                ) {
                    const updatedUserProfile = {
                        ...userProfile,
                        customer: {
                            ...userProfile.customer,
                            isSubscribed: true,
                        },
                    };

                    cache.writeQuery<
                        GetUserProfileQuery,
                        GetUserProfileQueryVariables
                    >({
                        query: GetUserProfileDocument,
                        data: updatedUserProfile,
                    });
                }
            },
        });
    };

    return [subscribeNewsletter, subscribeNewsletterResponse] as const;
};
