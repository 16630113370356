import {
    OrdersByDateArgs,
    OrdersService,
    OrdersArgs,
    Orders,
    OrdersPaginated,
    OrdersByIdArgs,
    OrderArgs,
    InvoicesByOrderIdArgs,
    ProductImageBySkuArgs,
    OrderStatusArgs,
    OrderDetails,
  } from "../interfaces/services/ordersService";
  import { BoutikEC2ProxyRepository } from "../interfaces/repositories/boutikEC2ProxyRepository";
 

  export class OrdersServiceImpl implements OrdersService {
    private boutikEC2ProxyRepository: BoutikEC2ProxyRepository;
  
    constructor(boutikEC2ProxyRepository: BoutikEC2ProxyRepository) {
      this.boutikEC2ProxyRepository = boutikEC2ProxyRepository;
    }
  
    async getOrdersByDate(ordersByDateArgs: OrdersByDateArgs): Promise<OrdersPaginated | null> {
      return await this.boutikEC2ProxyRepository
        .getOrdersByDate(ordersByDateArgs)
        .then((ordersPaginated: OrdersPaginated | null) => ordersPaginated)
        .catch((error: any) => {
          throw error;
        });
    }
  
    async getOrdersById(ordersByIdArgs: OrdersByIdArgs): Promise<OrdersPaginated | null> {
      return await this.boutikEC2ProxyRepository
        .getOrdersById(ordersByIdArgs)
        .then((orderPaginated: OrdersPaginated | null) => {
          return orderPaginated;
        })
        .catch((error: any) => {
          throw error;
        });
    }
  
    async getOrders(ordersArgs: OrdersArgs): Promise<Orders | null> {
      return await this.boutikEC2ProxyRepository
        .getOrders(ordersArgs)
        .then((orders: Orders | null) => {
          return orders;
        })
        .catch((error: any) => {
          throw error;
        });
    }
  
    async getOrder(orderArgs: OrderArgs): Promise<OrderDetails | null> {
      return await this.boutikEC2ProxyRepository
        .getOrder(orderArgs)
        .then((orders: OrderDetails | null) => orders)
        .catch((error: any) => {
          throw error;
        });
    }
  
    async getInvoicesByOrderId(invoicesByOrderIdArgs: InvoicesByOrderIdArgs): Promise<any | null> {
      return await this.boutikEC2ProxyRepository
        .getInvoicesByOrderId(invoicesByOrderIdArgs)
        .then((invoices: any | null) => {
          return invoices;
        })
        .catch((error: any) => {
          throw error;
        });
    }
  
    async getProductImage(productImageBySkuArgs: ProductImageBySkuArgs): Promise<any | null> {
      return await this.boutikEC2ProxyRepository
        .getProductImage(productImageBySkuArgs)
        .then((image: any | null) => {
          return image;
        })
        .catch((error: any) => {
          throw error;
        });
    }
  
    async getShippingStatus(orderStatusArgs: OrderStatusArgs): Promise<any | null> {
      return await this.boutikEC2ProxyRepository
        .getShippingStatus(orderStatusArgs)
        .then((orderStatus: any | null) => {
          return orderStatus;
        })
        .catch((error: any) => {
          throw error;
        });
    }
  }
  