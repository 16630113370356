import {
    GetCustomerAddressesDocument,
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables,
    useRemoveAddressMutation,
} from '../graphql';

import { useBoutikService } from '../../boutikService';
import react from 'react';

export interface IRemoveAddressInput {
    id: number;
}

export const useRemoveAddressAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [removeAddressMutation, removeAddressMutationResponse] =
        useRemoveAddressMutation();

    const removeAddress = async (adressId: IRemoveAddressInput) => {
        assertAuthenticated();

        await removeAddressMutation({
            variables: adressId,
            update: (cache) => {
                const customerAddresses = cache.readQuery<
                    GetCustomerAddressesQuery,
                    GetCustomerAddressesQueryVariables
                >({ query: GetCustomerAddressesDocument });

                if (customerAddresses?.customer?.addresses) {
                    const updatedCustomerAddresses = {
                        ...customerAddresses,
                        customer: {
                            ...customerAddresses.customer,
                            addresses:
                                customerAddresses.customer?.addresses.filter(
                                    (a) => a!.id !== adressId.id
                                ),
                        },
                    };

                    cache.writeQuery<
                        GetCustomerAddressesQuery,
                        GetCustomerAddressesQueryVariables
                    >({
                        query: GetCustomerAddressesDocument,
                        data: updatedCustomerAddresses,
                    });
                }
            },
        });
    };

    return [removeAddress, removeAddressMutationResponse] as const;
};
