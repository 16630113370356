import {
    PreloadAfterCustomerSignInDocument,
    PreloadAfterCustomerSignInQuery,
    PreloadAfterCustomerSignInQueryVariables,
} from '../graphql/preloadAfterCustomerSignInQuery.generated';

import { IBoutikServiceContextInternalAPI } from '../../boutikService/BoutikServiceContext';
import { invalidateCartCheckoutDetails } from '../../cart/actions/apolloCacheUtils';
import { useApolloClient } from '@apollo/client';
import { useBoutikService } from '../../boutikService';
import react from 'react';
import { useCompletePasswordResetMutation } from '../graphql';

export const useCompletePasswordResetAction = () => {
    const { boutikService, assertNotAuthenticated, setAccessToken } =
        useBoutikService() as IBoutikServiceContextInternalAPI;
    const [completePasswordResetMutation, completePasswordResetMutationResult] =
        useCompletePasswordResetMutation();
    const apolloClient = useApolloClient();

    const completePasswordReset = async (
        email: string,
        resetPasswordToken: string,
        newPassword: string
    ) => {
        assertNotAuthenticated();

        const response = await completePasswordResetMutation({
            variables: { email, resetPasswordToken, newPassword },
        });

        if (response.data?.generateCustomerToken?.token) {
            // FIXME: Find a better strategy here...
            boutikService.accessToken =
                response.data.generateCustomerToken.token;

            await apolloClient.query<
                PreloadAfterCustomerSignInQuery,
                PreloadAfterCustomerSignInQueryVariables
            >({
                query: PreloadAfterCustomerSignInDocument,
                fetchPolicy: 'network-only',
            });

            invalidateCartCheckoutDetails(apolloClient.cache);

            setAccessToken(response.data.generateCustomerToken.token, 3600);
        }
    };

    return [
        completePasswordReset,
        completePasswordResetMutationResult,
    ] as const;
};
