import { useBoutikService } from '../../boutikService';
import react from 'react';
import { useUpdateCustomerMutation } from '../graphql';

export interface IUpdateCustomerInput {
    firstname?: string;
    lastname?: string;
    is_subscribed?: boolean;
}

export const useUpdateCustomerAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [updateCustomerMutation, updateAddressMutationResponse] =
        useUpdateCustomerMutation();

    const updateCustomer = async (customer: IUpdateCustomerInput) => {
        assertAuthenticated();

        await updateCustomerMutation({
            variables: customer,
        });
    };

    return [updateCustomer, updateAddressMutationResponse] as const;
};
