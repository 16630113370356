import { useBoutikService } from '../../boutikService';
import react from 'react';
import { useChangePasswordMutation } from '../graphql';

export interface IChangePasswordInput {
    currentPassword: string;
    newPassword: string;
}

export const useChangePasswordAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [changePasswordMutation, changePasswordMutationResponse] =
        useChangePasswordMutation();

    const changePassword = async (passwordInfos: IChangePasswordInput) => {
            assertAuthenticated();

            await changePasswordMutation({
                variables: passwordInfos,
            });
        }

    return [changePassword, changePasswordMutationResponse] as const;
};
